import React from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, Auth } from 'firebase/auth';
import { getDatabase, Database, connectDatabaseEmulator, onValue, ref } from 'firebase/database';
import { 
  getFirestore, 
  Firestore, 
  enableIndexedDbPersistence,
  enableMultiTabIndexedDbPersistence,
  initializeFirestore,
  CACHE_SIZE_UNLIMITED
} from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator, Functions } from 'firebase/functions';
import { getStorage, FirebaseStorage } from 'firebase/storage';

const config = {
  apiKey: import.meta.env.VITE_APP_API_KEY,
  authDomain: import.meta.env.VITE_APP_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_APP_DATABASE_URL,
  projectId: import.meta.env.VITE_APP_PROJECT_ID,
  storageBucket: import.meta.env.VITE_APP_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_APP_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_APP_APP_ID,
  measurementId: import.meta.env.VITE_APP_MEASUREMENT_ID,
};

const app = initializeApp(config);

// Initialize services
const auth = getAuth(app);
const database = getDatabase(app);

// Initialize Firestore with persistence enabled
const firestore = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
});

const productionDb = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
}, 
'prod-db',
);

const devDb = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
}, 
'dev-db',
);

const stagingDb = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
}, 
'staging-db',
); 

const firestoreBackup = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
}, 
'backup-db',
);

// Enable persistence right after initialization
enableIndexedDbPersistence(firestore).catch((err) => {
  if (err.code === 'failed-precondition') {
    // Multiple tabs open, persistence enabled in another tab
    console.warn('Firebase persistence has already been enabled in another tab');
  } else if (err.code === 'unimplemented') {
    // The current browser does not support persistence
    console.warn('Firebase persistence is not supported in this browser');
  }
});

// Function to check if IndexedDB is available and working
const checkIndexedDB = () => new Promise<boolean>((resolve) => {
  if (!window.indexedDB) {
    console.warn('IndexedDB not available');
    resolve(false);
    return;
  }

  try {
    // Try to open a test database
    const test = window.indexedDB.open('test');
    test.onerror = () => {
      console.warn('IndexedDB test failed');
      resolve(false);
    };
    test.onsuccess = () => {
      test.result.close();
      window.indexedDB.deleteDatabase('test');
      resolve(true);
    };
  } catch (e) {
    console.warn('IndexedDB test threw an error:', e);
    resolve(false);
  }
});

// Create a promise that resolves when initialization is ready
const persistencePromise = new Promise<void>(async (resolve) => {
  try {
    // First check if IndexedDB is available and working
    const indexedDBAvailable = await checkIndexedDB();
    if (!indexedDBAvailable) {
      console.warn('IndexedDB not available, skipping persistence');
      resolve();
      return;
    }

    // Try to enable persistence
    try {
      await enableMultiTabIndexedDbPersistence(firestore);
      console.log('Multi-tab persistence enabled');
    } catch (multiTabError: any) {
      console.warn('Multi-tab persistence failed:', multiTabError?.message || multiTabError);
      
      if (multiTabError?.code !== 'failed-precondition') {
        try {
          await enableIndexedDbPersistence(firestore);
          console.log('Single-tab persistence enabled');
        } catch (err: any) {
          console.warn('Single-tab persistence failed:', err?.message || err);
        }
      } else {
        console.log('Another tab has persistence, continuing...');
      }
    }
  } catch (e) {
    console.warn('Persistence setup failed:', e);
  }
  
  // Always resolve to not block operations
  resolve();
});

// Monitor realtime database connection state
let connectionAttempts = 0;
const MAX_RETRY_ATTEMPTS = 5;
const INITIAL_RETRY_DELAY = 1000;
let isConnected = false;

const monitorConnection = () => {
  try {
    const connectedRef = ref(database, '.info/connected');
    onValue(connectedRef, (snap) => {
      isConnected = snap.val() === true;
      if (isConnected) {
        console.log('Connected to Firebase Realtime Database');
        connectionAttempts = 0;
      } else {
        console.warn('Disconnected from Firebase Realtime Database');
        if (connectionAttempts < MAX_RETRY_ATTEMPTS) {
          const delay = Math.min(INITIAL_RETRY_DELAY * Math.pow(2, connectionAttempts), 30000);
          connectionAttempts++;
          console.log(`Attempting reconnection in ${delay}ms (attempt ${connectionAttempts}/${MAX_RETRY_ATTEMPTS})`);
          setTimeout(monitorConnection, delay);
        } else {
          console.error('Max reconnection attempts reached');
        }
      }
    }, {
      onlyOnce: false
    });
  } catch (e) {
    console.error('Error setting up connection monitoring:', e);
  }
};

monitorConnection();

const storage = getStorage(app);

// Initialize functions with region and error handling
let functions: Functions | null = null;
try {
  functions = getFunctions(app, 'us-central1');
  if (import.meta.env.DEV) {
    connectFunctionsEmulator(functions, 'localhost', 5001);
  }
} catch (error) {
  console.warn('Firebase Functions not available:', error);
}

interface FirebaseContext {
  app: ReturnType<typeof initializeApp>;
  auth: Auth;
  database: Database;
  firestore: Firestore;
  firestoreBackup: Firestore;
  productionDb: Firestore;
  devDb: Firestore;
  stagingDb: Firestore;
  functions: Functions | null;
  storage: FirebaseStorage;
}

const firebaseContext = React.createContext<FirebaseContext>({
  app,
  auth,
  database,
  firestore,
  productionDb,
  devDb,
  stagingDb,
  firestoreBackup,
  functions,
  storage,
});

// Export the initialized instances and persistence promise
export {
  app,
  auth,
  database,
  firestore,
  firestoreBackup,
  functions,
  storage,
  persistencePromise,
  isConnected
};

const useFirebase = () => React.useContext(firebaseContext);
export default useFirebase;
