const records = 'customers';
const contacts = 'customer-contacts';
const shippingAddresses = 'customer-shipping-addresses';
const terms = 'terms';

export default {
  records,
  contacts,
  shippingAddresses,
  terms,
};
