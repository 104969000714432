const records = 'inventory-items';
const bins = 'inventory-bins';
const adjustments = 'inventory-adjustments';
const snapshots = 'inventory-snapshots';
const receptions = 'inventory-receptions';

export default {
  records,
  bins,
  adjustments,
  snapshots,
  receptions,
};
