import React, { useEffect, useState } from 'react';
import { onValue, ref } from 'firebase/database';
import { collection, getDocs, limit, query } from 'firebase/firestore';
import ScopedComponent from 'shared/components/Utility/ScopedComponent';
import { useRecoilValue } from 'recoil';
import { userSettingsAtom } from 'shared/state/siteState';
import db, { getDb } from 'shared/db';
import styled from 'styled-components';
import { Alert } from 'antd';
import useFirebase from '../../vendor/Firebase';

interface ConnectionStatus {
  isConnected: boolean;
  firestoreInitialized: boolean;
  indexedDBAvailable: boolean;
  persistenceEnabled: boolean;
  lastError: string | null;
}

const FirebaseStatus: React.FC = () => {
  const userSettings = useRecoilValue(userSettingsAtom);
  const [status, setStatus] = useState<ConnectionStatus>({
    isConnected: false,
    firestoreInitialized: false,
    indexedDBAvailable: false,
    persistenceEnabled: false,
    lastError: null
  });

  useEffect(() => {
    // Check IndexedDB availability
    const checkIndexedDB = () => {
      try {
        const test = window.indexedDB.open('test');
        test.onerror = () => {
          setStatus(prev => ({ ...prev, indexedDBAvailable: false, lastError: 'IndexedDB test failed' }));
        };
        test.onsuccess = () => {
          test.result.close();
          window.indexedDB.deleteDatabase('test');
          setStatus(prev => ({ ...prev, indexedDBAvailable: true }));
        };
      } catch (e) {
        setStatus(prev => ({ ...prev, indexedDBAvailable: false, lastError: String(e) }));
      }
    };

    // Monitor Realtime Database connection
    const connectedRef = ref(db.database, '.info/connected');
    const unsubscribe = onValue(connectedRef, (snap) => {
      const connected = snap.val() === true;
      setStatus(prev => ({ ...prev, isConnected: connected }));
    });

    // Check Firestore initialization and persistence
    const checkFirestore = async () => {
      try {
        const testQuery = query(collection(db.firestore, '_test_'), limit(1));
        await getDocs(testQuery);
        setStatus(prev => ({ 
          ...prev, 
          firestoreInitialized: true,
          // If we can query Firestore and IndexedDB is available, assume persistence is working
          persistenceEnabled: window.indexedDB !== undefined
        }));
      } catch (error) {
        setStatus(prev => ({ 
          ...prev, 
          firestoreInitialized: false,
          lastError: `Firestore test failed: ${error.message}`
        }));
      }
    };

    checkIndexedDB();
    checkFirestore();

    return () => {
      unsubscribe();
    };
  }, []);

  // Only render if dev tools are enabled
  if (!userSettings?.boolean?.showDevTools) {
    return null;
  }

  return (
    <ScopedComponent whitelist={['keithh@wildwoodmfg.com']}>
      <div style={{ 
        position: 'fixed', 
        bottom: 10, 
        right: 10, 
        background: '#f5f5f5', 
        padding: '10px',
        border: '1px solid #ddd',
        borderRadius: '4px',
        fontSize: '12px',
        zIndex: 9999
      }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
          <h4 style={{ margin: 0 }}>Firebase Status</h4>
        </div>
        <div>Connected: {status.isConnected ? '✅' : '❌'}</div>
        <div>Firestore Init: {status.firestoreInitialized ? '✅' : '❌'}</div>
        <div>IndexedDB: {status.indexedDBAvailable ? '✅' : '❌'}</div>
        <div>Persistence: {status.persistenceEnabled ? '✅' : '❌'}</div>
        {status.lastError && (
          <div style={{ color: 'red', marginTop: '8px' }}>
            Last Error: {status.lastError}
          </div>
        )}
      </div>
    </ScopedComponent>
  );
};

export default FirebaseStatus; 