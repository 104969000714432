import React, { useContext, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  every, find, flatten, includes, sortBy,
} from 'lodash-es';
import qs from 'qs';
import {
  shopOperatorsObjectAtom, userSettingsAtom,
} from 'shared/state/siteState';
import { routerStepsAtom, customerUserEmailsAtom } from 'shared/state/routingState';
import {
  bodyDataAtom, customerPartsAtom, neckDataAtom,
} from 'shared/state/pricingState';

import {
  partConfigTermsAtom,
  partConfigTermTypesAtom,
  partViewerConfigDataAtom,
  newCustomerPartsAtom,
} from 'shared/state/partViewState';
import useFirebase from 'vendor/Firebase';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import { useParams } from 'react-router-dom';
import {
  glCodesAtom,
  ncCorrectiveActionsObjectAtom,
  ncReasonsObjectAtom,
  ncRecordsAtom,
  productCodesAtom,
} from 'shared/state/utilState';
import db, { getDb } from 'shared/db';
import {
  accessToken, orderBillingAddress,
} from 'shared/data/jb_api';
import {
  ICustomerRecord,
  IGlCode,
  IInventoryPart,
  INCRecord,
  IOrderItem,
  IPurchaseOrder,
  IQATicket,
  IShipment,
  IShippingAddress,
} from 'shared/types/dbRecords';
import {
  IContact,
  IProductCode,
} from 'shared/types/jb';
import { inventoryItemsAtom } from 'shared/state/inventoryState';
import { IVendor } from 'shared/types/vendor';
import { vendorRecordsAtom } from 'shared/state/vendorState';
import {
  currentShopOrderAtom, openOrdersDataAtom,
  orderBillingDataAtom,
  orderItemsAtom,
  orderShipmentsAtom,
  shopOrdersAtom,
} from 'shared/state/orderState';
import { urlQueryString } from 'shared/util';
import { useTestData } from 'shared/util/site';
import { ISalesOrder, IShopOrder } from 'pages/Orders/types';
import {
  activeCustomerDisplayAtom,
  currentCustomerAtom,
  currentCustomerShippingAddressesAtom,
  customerContactsAtom,
  customersAtom,
  customerShippingAddressesAtom,
} from 'shared/state/customerState';
import { devLog } from 'shared/util/logging';
import { purchaseOrdersAtom } from 'shared/state/purchaseOrderState';
import { configToDescription } from 'shared/partParser/util';
import { IConfigTerm, IConfigTermType, ICustomerPart } from 'shared/types/parts';
import { qualityAssuranceTicketsAtom } from 'shared/state/qualityAssuranceState';
import {
  collection,
  doc,
  getDocs,
  getDoc,
  query,
  where,
  onSnapshot,
} from 'firebase/firestore';
import { Database } from 'firebase/database';
import { ref, get, set } from 'firebase/database';
import { rewriteRealtimePath } from 'shared/db/helper';
interface IUserSettings {
  boolean: {
    useTestData: boolean;
    useWeightedPartsPerDay: boolean;
    showCNCLoad: boolean;
    showFinishingLoad: boolean;
    showDecimalWeights: boolean;
    showInventoryMargin: boolean;
  };
  string: Record<string, string>;
}

const DEPENDENCY_TIMEOUT = 30000; // 30 seconds timeout for each dependency

const wrapWithTimeout = async (promise: Promise<any>, name: string) => {
  const timeoutPromise = new Promise((_, reject) => {
    setTimeout(() => reject(new Error(`Dependency ${name} timed out after ${DEPENDENCY_TIMEOUT}ms`)), DEPENDENCY_TIMEOUT);
  });
  return Promise.race([promise, timeoutPromise]);
};

const useDependencies = (dependencyList: string[]) => {
  const { database, firestore } = useFirebase();
  // @ts-ignore
  const { currentUser } = useContext(AuthContext);
  const routeParams = useParams();
  const [routerSteps, setRouterSteps] = useRecoilState(routerStepsAtom);
  const [customers, setCustomers] = useRecoilState(customersAtom);
  const [customerContacts, setCustomerContacts] = useRecoilState(customerContactsAtom);
  const [customerShippingAddresses, setCustomerShippingAddresses] = useRecoilState(customerShippingAddressesAtom);
  const [ncRecords, setNcRecords] = useRecoilState(ncRecordsAtom);
  const [openOrders, setOpenOrders] = useRecoilState(openOrdersDataAtom);
  const [orders, setOrders] = useRecoilState(shopOrdersAtom);
  const [bodyData, setBodyData] = useRecoilState(bodyDataAtom);
  const [neckData, setNeckData] = useRecoilState(neckDataAtom);
  const [inventoryItems, setInventoryItems] = useRecoilState(inventoryItemsAtom);
  const [purchaseOrders, setPurchaseOrders] = useRecoilState(purchaseOrdersAtom);
  const [glCodes, setGlCodes] = useRecoilState(glCodesAtom);
  const [productCodes, setProductCodes] = useRecoilState(productCodesAtom);
  const [partViewerConfigData, setPartViewerConfigData] = useRecoilState(partViewerConfigDataAtom);
  const [ncReasonCodes, setNcReasonCodes] = useRecoilState(ncReasonsObjectAtom);
  const [ncCorrectiveActions, setNcCorrectiveActions] = useRecoilState(ncCorrectiveActionsObjectAtom);
  const [newParts, setNewParts] = useRecoilState(newCustomerPartsAtom);
  const [shopOperators, setShopOperators] = useRecoilState(shopOperatorsObjectAtom);
  const [orderItems, setOrderItems] = useRecoilState(orderItemsAtom);
  const [customerParts, setCustomerParts] = useRecoilState(customerPartsAtom);
  const [currentCustomerShippingAddresses, setCurrentCustomerShippingAddresses] = useRecoilState(currentCustomerShippingAddressesAtom);
  const [vendors, setVendors] = useRecoilState(vendorRecordsAtom);
  const [qaTickets, setQaTickets] = useRecoilState(qualityAssuranceTicketsAtom);
  const setCurrentShopOrder = useSetRecoilState(currentShopOrderAtom);
  const setOrderShipments = useSetRecoilState(orderShipmentsAtom);
  const [billingData, setBillingData] = useRecoilState(orderBillingDataAtom);
  const vendorsDbString = db.vendor.records;
  const [partConfigTerms, setPartConfigTerms] = useRecoilState(partConfigTermsAtom);
  const [partConfigTermTypes, setPartConfigTermTypes] = useRecoilState(partConfigTermTypesAtom);
  const customerEmails = useRecoilValue(customerUserEmailsAtom);
  const setCurrentCustomer = useSetRecoilState(currentCustomerAtom);
  const customerViewType = useRecoilValue(activeCustomerDisplayAtom);
  const [userSettings, setUserSettings] = useRecoilState(userSettingsAtom);

  const dbDeps = {
    routerSteps: {
      fn: async () => {
        try {
          const rootRef = ref(database, db.realtime.router);
          const snapshot = await wrapWithTimeout(get(rootRef), 'routerSteps');
          const data = snapshot.val();
          if (!data) {
            console.warn('Router steps data is empty');
            setRouterSteps([]);
            return;
          }
          setRouterSteps(data);
          devLog('useDependencies', 161, 'Router steps loaded');
          devLog('useDependencies', 162, data);
        } catch (error) {
          console.error('Failed to load router steps:', error);
          setRouterSteps([]);
        }
      },
      test: () => routerSteps !== null,
    },
    configTerms: {
      fn: async () => {
        const terms: Record<string, unknown> = {};
        const termTypes: IConfigTermType[] = [];
        const termTypesRef = collection(getDb('prod'), db.part.config.types);
        const termsRef = collection(getDb('prod'), db.part.config.terms);
        const termTypesDocs = await getDocs(termTypesRef);
        const termsDocs = await getDocs(termsRef);
        
        termTypesDocs.forEach((d) => {
          termTypes.push({ ...d.data(), id: d.id });
        });
        termsDocs.forEach((d) => {
          const termData = d.data();
          terms[d.id] = termData;
        });
        setPartConfigTerms(terms as IConfigTerm[]);
        setPartConfigTermTypes(termTypes as IConfigTermType[]);
      },
      test: () => partConfigTermTypes.length > 0 && Object.keys(partConfigTerms).length > 0,
    },
    customers: {
      fn: async () => {
        const includeInactive = includes(['all', 'inactive'], customerViewType);
        devLog('useDependencies', 115, `Customer view type: ${customerViewType}`);
        devLog('useDependencies', 115, `Show inactive customers: ${includeInactive}`);
        devLog('useDependencies', 115, routeParams);
        const customersRef = collection(db.firestore, db.customer.records);
        const customerDocs = await getDocs(customersRef);
        const _customers = customerDocs.docs.map((c) => c.data() as ICustomerRecord)
          .filter((c: ICustomerRecord) => c.active || includeInactive);
        setCustomers(sortBy(_customers, (d: ICustomerRecord) => d.DisplayName));

        if (includes(customerEmails.emails, currentUser.email)) {
          const login = currentUser.email.split('@')[0];
          const cust = find(_customers, (c: ICustomerRecord) => c.login === login);
          if (cust) setCurrentCustomer(cust);
        } else if (localStorage.getItem('currentCustomerId')) {
          const localId = localStorage.getItem('currentCustomerId');
          const cust = find(_customers, (c: ICustomerRecord) => c.id === localId);
          if (cust) setCurrentCustomer(cust);
        }
        devLog('useDependencies', 166, 'Customers loaded');
      },
      test: () => customers.length > 0,
    },
    newCustomerParts: {
      fn: async () => {
        const partsRef = collection(db.firestore, db.part.records);
        const partsQuery = query(partsRef, where('new', '==', true));
        onSnapshot(partsQuery, (snapshot) => {
          const data: ICustomerPart[] = snapshot.docs.map((d) => d.data() as ICustomerPart);
          setNewParts(data);
        });
      },
      test: () => newParts.length > 0,
    },
    ncConfig: {
      fn: async () => {
        const ncConfigRef = ref(database, '/ncConfig');
        const snapshot = await get(ncConfigRef);
        const data = snapshot.val();
        setNcCorrectiveActions(data.correctiveActions);
        setNcReasonCodes(data.reasonCodes);
      },
      test: () => (Object.values(ncCorrectiveActions).length > 0 && Object.values(ncReasonCodes).length > 0),
    },
    customerContacts: {
      fn: async () => {
        const contactsRef = collection(db.firestore, db.customer.contacts);
        const snap = await getDocs(contactsRef);
        const data: IContact[] = [];
        snap.forEach((doc) => {
          data.push(doc.data().contacts as IContact);
        });
        setCustomerContacts(flatten(data));
      },
      test: () => customerContacts.length > 0,
    },
    glCodes: {
      fn: async () => {
        const glCodesRef = collection(db.firestore, db.accounting.glCodes);
        const glCodeDocs = await getDocs(glCodesRef);
        const data: IGlCode[] = glCodeDocs.docs.map((g) => g.data() as IGlCode);
        setGlCodes(data);
        devLog('useDependencies', 186, 'GL Codes loaded');
        devLog('useDependencies', 187, data);
      },
      test: () => glCodes.length > 0,
    },
    inventory: {
      fn: async () => {
        try {
          const inventoryRef = collection(db.firestore, db.inventory.records);
          const inventoryDocs = await wrapWithTimeout(getDocs(inventoryRef), 'inventory');
          const data = inventoryDocs.docs.map((d) => d.data() as IInventoryPart);
          
          // Process items in batches to avoid long-running synchronous operations
          const batchSize = 50;
          const processedItems = [];
          
          for (let i = 0; i < data.length; i += batchSize) {
            const batch = data.slice(i, i + batchSize);
            const processedBatch = batch.map((d) => {
              try {
                if (d.Children?.length) {
                  const children = d.Children.map((c) => {
                    const child = find(data, (i) => i.Sku === c.sku);
                    return child;
                  }).filter((i) => i);
                  return { ...d, Children: children };
                }
                return d;
              } catch (error) {
                console.error('Error processing inventory item:', error);
                return d;
              }
            });
            processedItems.push(...processedBatch);
            
            // Allow other operations to run between batches
            await new Promise(resolve => setTimeout(resolve, 0));
          }
          
          setInventoryItems(processedItems);
          devLog('useDependencies', 195, 'Inventory loaded');
        } catch (error) {
          console.error('Failed to load inventory:', error);
          setInventoryItems([]);
        }
      },
      test: () => inventoryItems !== null,
    },
    purchaseOrders: {
      fn: async () => {
        const poRef = collection(db.firestore, db.purchaseOrder.records);
        const snapshot = await getDocs(poRef);
        const data: IPurchaseOrder[] = snapshot.docs.map((d) => d.data() as IPurchaseOrder);
        if (!data.length) setPurchaseOrders([]);
        setPurchaseOrders(data);
        devLog('useDependencies', 208, 'Purchase Orders loaded');
      },
      test: () => purchaseOrders.length > 0,
    },
    openOrders: {
      fn: async () => {
        const ordersRef = collection(db.firestore, db.order.records);
        const ordersQuery = query(ordersRef, where('completed', '!=', true));
        const orderDocs = await getDocs(ordersQuery);
        
        const _orders = await Promise.all(orderDocs.docs.map(async (d) => {
          const data = d.data() as IShopOrder;
          const itemDocRef = doc(db.firestore, db.order.items, d.id);
          const itemDoc = await getDoc(itemDocRef);
          const _orderItems = itemDoc.exists() ? (itemDoc.data()?.orderItems || []) as IOrderItem[] : [];
          return { ...data, orderItems: _orderItems };
        }));
        setOpenOrders(_orders);
      },
      test: () => openOrders.length > 0,
    },
    allOrders: {
      fn: async () => {
        const ordersRef = collection(db.firestore, db.order.records);
        const snapshot = await getDocs(ordersRef);
        const data: ISalesOrder[] = [];
        snapshot.forEach((d) => {
          data.push(d.data() as ISalesOrder);
        });
        setOrders(data);
      },
      test: () => orders.length > 0,
    },
    productCodes: {
      fn: async () => {
        const productCodesRef = collection(db.firestore, db.accounting.productCodes);
        const productCodeDocs = await getDocs(productCodesRef);
        const data: IProductCode[] = productCodeDocs.docs.map((p: any) => p.data() as IProductCode);
        setProductCodes(data);
        devLog('useDependencies', 243, 'Product Codes loaded');
        devLog('useDependencies', 244, data);
      },
      test: () => productCodes.length > 0,
    },
    partData: {
      fn: async () => {
        devLog('useDependencies', 250, 'Loading part data');
        const rootRef = ref(database, '/');
        const snapshot = await get(rootRef);
        const data =  snapshot.val();
        const bData = data[db.realtime.body];
        const nData = data[db.realtime.neck];
        setBodyData(bData);
        setNeckData(nData);
      },
      test: () => Object.keys(bodyData).length > 0 && Object.keys(neckData).length > 0,
    },
    operatorData: {
      fn: async () => {
        const operatorsRef = ref(database, rewriteRealtimePath(db.realtime.operators, 'prod'));
        const operatorsSnapshot = await get(operatorsRef);
        const operators = operatorsSnapshot.val();
        setShopOperators(operators);
      },
      test: () => !!shopOperators.length,
    },
    orderBillingData: {
      fn: async () => {
        const currentOrderId = qs.parse(window.location.href.replace('?', '')).orderId;
        if (currentOrderId === 'ph') {
          setBillingData(false);
          return;
        }
        if (currentOrderId) {
          const orderDocRef = doc(db.firestore, db.order.records, currentOrderId as string);
          const orderDoc = await getDoc(orderDocRef);
          if (!orderDoc.exists()) setBillingData(false);
          const id = orderDoc.data()?.customer.id;

          accessToken().then((token) => {
            orderBillingAddress(token, id)
              .then((res) => {
                setBillingData(res);
                devLog('useDependencies', 282, 'Billing info loaded');
              })
              .catch(() => setBillingData(false));
          });
        }
      },
      test: () => billingData !== null,
    },
    shopOrderData: {
      fn: async () => {
        const currentOrderId = qs.parse(window.location.href.replace('?', '')).orderId;
        if (currentOrderId) {
          const shopOrderRef = doc(db.firestore, db.order.records, currentOrderId as string);
          const shopOrderDoc = await getDoc(shopOrderRef);
          const shopOrderData = shopOrderDoc.data();
          
          if (!shopOrderDoc.exists() || !shopOrderData) {
            setOrderItems([null]);
            return;
          }
          
          setCurrentShopOrder(shopOrderData as IShopOrder);
          setCurrentCustomer(shopOrderData.customer);

          const partsRef = collection(db.firestore, db.part.records);
          const partsQuery = query(partsRef, where('customer', '==', shopOrderData.customer.DisplayName));
          const customerPartDocs = await getDocs(partsQuery);
          
          setCustomerParts(customerPartDocs.docs.map((d) => {
            const partData = d.data();
            return { ...partData, Description: configToDescription(partData.config) };
          }));

          const orderItemRef = doc(db.firestore, db.order.items, shopOrderDoc.id);
          const orderItemDoc = await getDoc(orderItemRef);
          const itemsData = orderItemDoc.data();
          
          if (!orderItemDoc.exists() || !itemsData || Object.keys(itemsData).length === 0 || itemsData.orderItems.filter((i: IOrderItem) => i).length === 0) {
            setOrderItems([null]);
          } else {
            const orderItemData = itemsData.orderItems
              .filter((i: IOrderItem) => !i.Sku.match(/^[1]/))
              .map((i: IOrderItem) => ({
                ...i,
                Sku: i.Sku,
                Description: i.Description,
                quantityOpen: i.quantityOrdered - (i.quantityShipped + i.quantityCanceled),
              }));
            setOrderItems(orderItemData);
            devLog('useDependencies', 338, 'shop order items loaded');
          }
          const shippingAddressRef = doc(db.firestore, db.customer.shippingAddresses, shopOrderData.customer.DisplayName);
          const shippingAddressesDoc = await getDoc(shippingAddressRef);
          const shippingAddresses = shippingAddressesDoc.data()?.shippingAddresses;
          if (!shippingAddressesDoc.exists() || !shippingAddresses || shippingAddresses.filter((i: IShippingAddress) => i).length === 0) {
            setCurrentCustomerShippingAddresses([]);
          } else {
            setCurrentCustomerShippingAddresses(shippingAddresses);
            devLog('useDependencies', 374, `shipping addresses for ${shopOrderData.customer.DisplayName} loaded`);
          }

          const shipmentsRef = collection(db.firestore, db.order.shipments);
          const shipmentsQuery = query(shipmentsRef, where('orderId', '==', currentOrderId));
          const orderShipmentsDoc = await getDocs(shipmentsQuery);
          const orderShipments = orderShipmentsDoc.docs.map((d) => d.data() as IShipment);
          setOrderShipments(orderShipments);
        } else {
          devLog('useDependencies', 376, 'No order data found, exiting dependency loader');
          setOrderItems([null]);
        }
      },
      test: () => orderItems && !!orderItems.length,
    },
    shippingAddresses: {
      fn: async () => {
        const { customer } = qs.parse(urlQueryString(window.location.href));
        const shippingAddressRef = doc(db.firestore, db.customer.shippingAddresses, customer as string);
        const shippingAddressesDoc = await getDoc(shippingAddressRef);
        const shippingAddresses = shippingAddressesDoc.data()?.shippingAddresses;
        if (!shippingAddressesDoc.exists() || !shippingAddresses || shippingAddresses.filter((i: IOrderItem) => i).length === 0) {
          setCurrentCustomerShippingAddresses([null]);
        } else {
          setCurrentCustomerShippingAddresses(shippingAddresses as IShippingAddress[]);
          devLog('useDependencies', 338, `shipping addresses for ${customer} loaded`);
        }
      },
      test: () => !!currentCustomerShippingAddresses.length,
    },
    qualityAssuranceTickets: {
      fn: async () => {
        const qaTicketsRef = collection(db.firestore, db.qualityAssurance.tickets);
        const qaTicketDocs = await getDocs(qaTicketsRef);
        const qaTicketData = qaTicketDocs.docs.map((d: any) => d.data() as IQATicket);
        setQaTickets(qaTicketData);
      },
      test: () => qaTickets.length > 0,
    },
    vendors: {
      fn: async () => {
        const vendorsRef = collection(db.firestore, vendorsDbString);
        const snapshot = await getDocs(vendorsRef);
        const data: IVendor[] = [];
        snapshot.forEach((v: any) => {
          data.push(v.data() as IVendor);
        });
        setVendors(data);
      },
      test: () => vendors.length > 0,
    },
    settings: {
      fn: async () => {
        try {
          devLog('useDependencies', 461, 'Loading settings');
          if (!currentUser?.email) {
            console.warn('No current user email available for settings load');
            // Set default settings instead of returning
            const defaultSettings = {
              boolean: {
                useTestData: false,
                useWeightedPartsPerDay: false,
                showCNCLoad: false,
                showFinishingLoad: false,
                showDecimalWeights: false,
                showInventoryMargin: false,
              },
              string: { scrollToLocation: '' },
            };
            setUserSettings(defaultSettings);
            return;
          }

          if (!includes(customerEmails.emails, currentUser.email)) {
            const userKey = currentUser.email.split('@')[0];
            const settingsRef = ref(database, `/settings/${userKey}`);
            
            try {
              const snapshot = await wrapWithTimeout(get(settingsRef), 'settings');
              const data = snapshot.val();
              
              if (data) {
                devLog('useDependencies', 473, 'Loading user settings');
                setUserSettings(data);
                return;
              }
              
              const defaultData = {
                boolean: {
                  useTestData: false,
                  useWeightedPartsPerDay: false,
                  showCNCLoad: false,
                  showFinishingLoad: false,
                  showDecimalWeights: false,
                  showInventoryMargin: false,
                },
                string: { scrollToLocation: '' },
              };
              
              await set(settingsRef, defaultData);
              setUserSettings(defaultData);
            } catch (error) {
              console.error('Error loading settings:', error);
              // Set default settings on error
              setUserSettings({
                boolean: {
                  useTestData: false,
                  useWeightedPartsPerDay: false,
                  showCNCLoad: false,
                  showFinishingLoad: false,
                  showDecimalWeights: false,
                  showInventoryMargin: false,
                },
                string: { scrollToLocation: '' },
              });
            }
          } else {
            setUserSettings({
              boolean: {
                useTestData: false,
                useWeightedPartsPerDay: false,
                showCNCLoad: false,
                showFinishingLoad: false,
                showDecimalWeights: false,
                showInventoryMargin: false,
              },
              string: { scrollToLocation: '' },
            });
          }
        } catch (error) {
          console.error('Settings load failed:', error);
          // Set default settings on error
          setUserSettings({
            boolean: {
              useTestData: false,
              useWeightedPartsPerDay: false,
              showCNCLoad: false,
              showFinishingLoad: false,
              showDecimalWeights: false,
              showInventoryMargin: false,
            },
            string: { scrollToLocation: '' },
          });
        }
      },
      test: () => userSettings !== null && Object.keys(userSettings).length > 0,
    },
  };

  // Modify the depsReady function to be more lenient
  const depsReady = () => {
    try {
      return every(dependencyList.map((d: string) => {
        const dep = dbDeps[d];
        if (!dep) {
          console.warn(`Unknown dependency: ${d}`);
          return true; // Don't block on unknown dependencies
        }
        return dep.test();
      }));
    } catch (error) {
      console.error('Error checking dependencies:', error);
      return false;
    }
  };

  useEffect(() => {
    if (!currentUser) return;
    
    const loadDependencies = async () => {
      for (const dep of dependencyList) {
        try {
          if (dbDeps[dep]) {
            await dbDeps[dep].fn();
          } else {
            console.warn(`Unknown dependency: ${dep}`);
          }
        } catch (error) {
          console.error(`Failed to load dependency ${dep}:`, error);
        }
      }
    };

    loadDependencies();
  }, [currentUser, dependencyList]);

  return depsReady;
};

export default useDependencies;
