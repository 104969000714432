import { DbInstance } from '../types/firebase';
 
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import { app } from 'vendor/Firebase';

import accounting from 'shared/db/accounting';
import customer from 'shared/db/customer';
import inventory from 'shared/db/inventory';
import order from 'shared/db/orders';
import part from 'shared/db/parts';
import purchaseOrder from 'shared/db/purchaseOrders';
import qualityAssurance from 'shared/db/qualityAssurance';
import realtime from 'shared/db/realtime';
import vendor from 'shared/db/vendor';
import { useTestData } from 'shared/util/site';
import { isDevSite } from 'shared/util/site';
import { includes } from 'lodash-es';

export const getDb = (env: 'default' | 'prod' | 'dev' | 'staging' | null = null) => {
  if (env === 'default') return getFirestore(app);
  if (includes(['prod', 'dev', 'staging'], env)) return getFirestore(app, `${env}-db`)
  // if env is omitted or null, use the site environment to determine the db
  if (isDevSite()) return getFirestore(app, 'dev-db');
  if (useTestData) return getFirestore(app, 'staging-db');
  return getFirestore(app, 'prod-db');
};

const db: DbInstance = {
  firestore: getDb('prod'),
  database: getDatabase(app),
  accounting,
  customer,
  inventory,
  order,
  part,
  purchaseOrder,
  qualityAssurance,
  realtime,
  vendor,
};

export default db;