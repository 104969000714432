const records = 'parts';
const bom = 'part-bom-data';
const pricing = 'part-pricing-data';
const history = 'part-history';
const config = {
  // we always want to use the prod environment for config
  // so what we need to do is specify the database when requesting 
  // any db.part.config.* collection.
  // do that by passing collection(getDb('prod'), db.part.config.terms)
  // etc.
  terms: 'part-config-terms',
  types: 'part-config-term-types',
}

export default {
  records,
  bom,
  pricing,
  history,
  config,
};
